import React, { useEffect, useContext } from 'react';

/* @Hooks */
import { useParams } from 'react-router-dom';
import { useMachine } from '@xstate/react';
import { useIntl } from 'react-intl';

/* @Api */
import { urlApi } from 'services/api/url';

/* @Components */
import { MainComponent } from './MainComponent';

/* @Utils */
import { getOsName } from 'utils/uaParser';
import { browserDeeplink } from '@macpaw/browser-deeplink';

/* @State Machines */
import { createDeepLinkPageMachine } from 'stateMachine/deepLinkPageMachine';

/* @Context */
import { UserAppInfoContext, USER_APP_INFO_STATUS } from 'contexts/UserAppInfoContext';

export const MainContainer = () => {
  const { locale } = useIntl();
  const { shortId } = useParams();
  const { userAppInfoStatus } = useContext(UserAppInfoContext);
  const [currentState, dispatchAction] = useMachine(createDeepLinkPageMachine({ shortId, locale }), {
    services: {
      getUrlInfo: async (context) => await urlApi.getUrl({ shortId: context.shortId, locale: context.locale }),
      tryCheckOsName: () =>
        new Promise((resolve, reject) => {
          const osName = getOsName();

          if (osName === 'macOS') {
            resolve();
          } else {
            reject();
          }
        }),
      tryOpenDeepLink: ({ urlInfo }) =>
        new Promise((resolve, reject) => {
          const deepLink = urlInfo.params?.macOs?.deepLink;

          if (!deepLink) {
            reject();

            return;
          }

          browserDeeplink(deepLink).then(resolve).catch(reject);
        }),
    },
  });

  useEffect(() => {
    dispatchAction('LOAD_URL_INFO');
  }, [dispatchAction]);

  useEffect(() => {
    if (currentState.value === 'openDeepLink' && userAppInfoStatus === USER_APP_INFO_STATUS.NOT_INSTALLED) {
      dispatchAction('SET_ERROR');
    }
  }, [userAppInfoStatus, dispatchAction, currentState.value]);

  return <MainComponent urlInfo={currentState.context.urlInfo} step={currentState.value} />;
};
