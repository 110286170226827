import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import { IntlProvider } from 'react-intl';

/* @Services */
import { logger } from 'services/logger';
import { loadLocalizationInfo } from 'services/localization';

/* @Components */
import { App } from 'components/App';
import { BrowserRouter as Router } from 'react-router-dom';

/* @Context */
import { UserAppInfoContext, USER_APP_INFO_STATUS } from 'contexts/UserAppInfoContext';

/* @Constants */
import { BASE_PATH } from 'constants/config';

logger.init();

const ClientApp = () => {
  const [{ locale, messages }, setLocalization] = useState({});
  const [userAppInfoStatus, setUserAppInfoStatus] = useState(USER_APP_INFO_STATUS.DEFAULT);

  useEffect(() => {
    loadLocalizationInfo().then(setLocalization);
  }, []);

  if (!messages) {
    // TODO: ADD LOADER COMPONENT
    return null;
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      <Router basename={BASE_PATH}>
        <UserAppInfoContext.Provider value={{ userAppInfoStatus, setUserAppInfoStatus }}>
          <App />
        </UserAppInfoContext.Provider>
      </Router>
    </IntlProvider>
  );
};

render(<ClientApp />, document.getElementById('root'));
