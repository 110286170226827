/* @Helpers */
import { createRequestOptions, createUrlWithQuery } from './helpers';

/* @Errors */
import { RequestError } from 'utils/RequestError';

/**
 *
 * @param {Object} params - params for make request
 * @param {string} params.url
 * @param {string} params.method
 * @param {object} params.headers
 * @param {BodyInit} params.data
 * @param {object|string} params.query
 */
export const request = async (params) => {
  const { url, query, ...options } = params;
  const urlWithQuery = createUrlWithQuery(url, query);
  const requestOptions = createRequestOptions(options);

  const response = await fetch(urlWithQuery, requestOptions);

  if (!response.ok) {
    throw new RequestError(response.statusText, response.status);
  }

  if (response.headers.get('content-type')?.includes('application/json')) {
    const json = await response.json();

    return json;
  }

  return response;
};
