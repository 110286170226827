import React from 'react';
import { func, shape, string } from 'prop-types';

/* @Hooks */
import { useIntl } from 'react-intl';

const propTypes = {
  productToDisplay: string,
  onDownloadApp: func,
  fallbackCta: shape({
    title: string,
    url: string,
  }),
};

export const ErrorComponent = ({ productToDisplay, onDownloadApp, fallbackCta }) => {
  const { formatMessage: trans } = useIntl();
  const fallbackUrl = fallbackCta?.url ? fallbackCta.url : 'https://macpaw.com/download/cleanmymac';
  const fallbackUrlTitle = fallbackCta?.title ? fallbackCta.title : trans({ id: 'downloadApp' }, { productToDisplay });

  return (
    <>
      <h1>{trans({ id: 'oopsMessage' })}</h1>
      <h2>{trans({ id: 'appIsNotInstalled' }, { productToDisplay })}</h2>
      <a href={fallbackUrl} onClick={onDownloadApp} className="main-button">
        {fallbackUrlTitle}
      </a>
    </>
  );
};

ErrorComponent.propTypes = propTypes;
