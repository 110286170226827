import React from 'react';
import { string, func, shape } from 'prop-types';

/* @Hooks */
import { useIntl } from 'react-intl';

const propTypes = {
  productToDisplay: string,
  h1Title: string,
  headerText: string,
  deepLink: shape({
    url: string,
    text: string,
  }).isRequired,
  onLaunchApp: func,
};

export const SuccessComponent = ({ productToDisplay, h1Title, headerText, deepLink, onLaunchApp }) => {
  const { formatMessage: trans } = useIntl();
  const deepLinkTitle = deepLink.title || trans({ id: 'runSmartScan' });

  return (
    <>
      <h1>{h1Title || trans({ id: 'openApp' }, { productToDisplay })}</h1>
      <h2>{headerText || trans({ id: 'clickAllowForOpenApp' })}</h2>
      <a href={deepLink?.url ?? ''} onClick={onLaunchApp} className="main-button">
        {deepLinkTitle}
      </a>
    </>
  );
};

SuccessComponent.propTypes = propTypes;
