import React from 'react';
import { func } from 'prop-types';

const propTypes = {
  component: func.isRequired,
};

export const AppRouteComponent = ({ component: Component, layout: Layout }) => {
  const LayoutComponent = Layout ? Layout : React.Fragment;

  return (
    <LayoutComponent>
      <Component />
    </LayoutComponent>
  );
};

AppRouteComponent.propTypes = propTypes;
