export const EVENT = {
  MACPAW: 'macpaw',
};

export const CATEGORY = {
  APP: 'MacPaw Shortener App',
};

export const ACTION = {
  CLICK_LAUNCH_APP: 'Click Launch App',
  CLICK_DOWNLOAD: 'Click Download',
  CLICK_OPEN_BLOG: 'Click Check Our Blog',
  PAGE_SHOWN: 'Page Shown',
  APP_INSTALLED_PAGE_SHOWN: 'App Installed Page Shown',
  APP_NOT_INSTALLED_PAGE_SHOWN: 'App Not Installed Page Shown',
};

export const LABEL = {
  CLEAN_MY_MAC_X: 'CleanMyMac X',
};

export const NON_INTERACTION = {
  TRUE: 'TRUE',
};
