import React from 'react';
import { string, element, array, oneOfType } from 'prop-types';
import classNames from 'classnames';

/* @Styles */
import './Container.scss';

const propTypes = {
  children: oneOfType([element, array]).isRequired,
  className: string,
};

export const ContainerComponent = ({ children, className }) => {
  const cx = classNames('container', { [className]: !!className });

  return <div className={cx}>{children}</div>;
};

ContainerComponent.propTypes = propTypes;
ContainerComponent.defaultProps = {
  className: '',
};
