import { createContext } from 'react';

export const USER_APP_INFO_STATUS = {
  DEFAULT: 0,
  INSTALLED: 1,
  NOT_INSTALLED: 2,
};

const userAppInfo = {
  userAppInfoStatus: USER_APP_INFO_STATUS.DEFAULT,
  setUserAppInfoStatus: () => {},
};

export const UserAppInfoContext = createContext(userAppInfo);
