import React from 'react';

/* @Hooks */
import { useIntl } from 'react-intl';
import { string } from 'prop-types';

const propTypes = {
  productToDisplay: string,
};

export const NotMacUserComponent = ({ productToDisplay }) => {
  const { formatMessage: trans } = useIntl();

  return (
    <>
      <h1>{trans({ id: 'linksWorkOnMac' })}</h1>
      <h2>
        {trans({ id: 'triedOpenAppOnPC' }, { productToDisplay })}
        <br />
        {trans({ id: 'tryAgainOnMac' })}
      </h2>
    </>
  );
};

NotMacUserComponent.propTypes = propTypes;
