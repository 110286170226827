/* @Services */
import { localizationApi } from 'services/api/localization';

/* @Utils */
import { getWindow } from 'utils/getWindow';

/* @Constants */
const SUPPORTED_LOCALES = {
  EN: 'en',
  DE: 'de',
  ES: 'es',
  FR: 'fr',
  JA: 'ja',
  RU: 'ru',
  ZH: 'zh',
};
const DEFAULT_LOCALE = SUPPORTED_LOCALES.EN;

const getCurrentLocale = () => {
  const window = getWindow();

  if (!window) {
    return DEFAULT_LOCALE;
  }

  const userLocale = window.navigator.language.split('-')[0];
  const currentLocale = Object.values(SUPPORTED_LOCALES).includes(userLocale) ? userLocale : DEFAULT_LOCALE;

  return currentLocale;
};

export const loadLocalizationInfo = () =>
  new Promise((resolve, reject) => {
    const locale = getCurrentLocale();

    localizationApi
      .getMessages(locale)
      .then((messages) => {
        resolve({ messages, locale });
      })
      .catch(reject);
  });
