import React from 'react';

/* @Components */
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';

/* @Styles */
import './Layout.scss';

export const LayoutComponent = ({ children }) => {
  return (
    <div className="main-app">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};
