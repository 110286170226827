export const loggerFactory = (loggerClient) => {
  const ERROR_TYPE = ['error', 'fatal'];
  const logger = {};

  const _captureException = (errorInfo) => {
    const { error, type, extraInfo } = errorInfo;

    loggerClient.track({
      error,
      type,
      extraInfo,
    });
  };

  ERROR_TYPE.forEach((errorType) => {
    logger[errorType] = (error, extraInfo) => {
      _captureException({
        error,
        extraInfo,
        type: errorType,
      });
    };
  });

  logger.init = () => {
    loggerClient.init();
  };

  return logger;
};
