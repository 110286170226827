import { Machine, assign } from 'xstate';

export const createDeepLinkPageMachine = ({ shortId, locale }) =>
  Machine(
    {
      id: 'deepLinkPageMachine',
      initial: 'idle',
      context: {
        urlInfo: null,
        shortId,
        locale,
      },
      states: {
        idle: {
          on: {
            LOAD_URL_INFO: 'loading',
          },
        },
        loading: {
          invoke: {
            id: 'loadUrlInfo',
            src: 'getUrlInfo',
            onDone: {
              target: 'osNameCheck',
              actions: 'setUrlInfo',
            },
            onError: 'error',
          },
        },
        osNameCheck: {
          invoke: {
            id: 'checkOsName',
            src: 'tryCheckOsName',
            onDone: 'deepLinkCheck',
            onError: 'notSupportedBrowser',
          },
        },
        deepLinkCheck: {
          invoke: {
            id: 'checkDeepLink',
            src: 'tryOpenDeepLink',
            onDone: 'openDeepLink',
            onError: 'openDeepLink',
          },
        },
        error: {
          type: 'final',
        },
        notSupportedBrowser: {
          type: 'final',
        },
        openDeepLink: {
          on: {
            SET_ERROR: 'error',
          },
        },
      },
    },
    {
      actions: {
        setUrlInfo: assign({
          urlInfo: (context, event) => event.data,
        }),
      },
    }
  );
