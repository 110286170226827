import React from 'react';

/* @Hooks */
import { useIntl } from 'react-intl';

export const Page404Component = () => {
  const { formatMessage: trans } = useIntl();

  return <h1>{trans({ id: 'page404Title' })}</h1>;
};
