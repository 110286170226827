import React from 'react';

/* @Components */
import { Container } from 'components/Container';

/* @Hooks */
import { useIntl } from 'react-intl';

/* @Styles */
import './footer.scss';

/* @Images */
import logoGreen from 'images/logo-green.svg';

export const FooterComponent = () => {
  const { formatMessage: trans } = useIntl();
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="legal-block">
        <Container>
          <div className="legal-block-in">
            <div className="legal-block-left">
              <a
                className="macpaw-logo"
                href="https://macpaw.com/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="MacPaw homepage"
              >
                <img src={logoGreen} width="32" alt="Green MacPaw logo" />
              </a>
              <div className="list-wrap">
                <ul className="footer-menu">
                  <li>
                    <a href="https://macpaw.com/cookies" target="_blank" rel="noopener noreferrer">
                      {trans({ id: 'cookiePolicy' })}
                    </a>
                  </li>
                  <li>
                    <a href="https://macpaw.com/policy" target="_blank" rel="noopener noreferrer">
                      {trans({ id: 'privacyPolicy' })}
                    </a>
                  </li>
                  <li>
                    <a href="https://macpaw.com/eula" target="_blank" rel="noopener noreferrer">
                      {trans({ id: 'userAgreement' })}
                    </a>
                  </li>
                  <li>
                    <a href="https://macpaw.com/refund-policy" target="_blank" rel="noopener noreferrer">
                      {trans({ id: 'refundPolicy' })}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://macpaw.com/support/cleanmymac/knowledgebase/uninstall-cleanmymac-x"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {trans({ id: 'uninstall' })}
                    </a>
                  </li>
                </ul>
                <div className="company-info">{trans({ id: 'copyrightInfo' }, { year })}</div>
              </div>
            </div>
            <div className="legal-block-right">
              <div className="dmca">{trans({ id: 'dmca' })}</div>
              <div className="protected">{trans({ id: 'protected' })}</div>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};
