import Bowser from 'bowser';

/* @Utils */
import { getWindow } from 'utils/getWindow';

/**
 * @returns {?string} os name
 */
export const getOsName = () => {
  const window = getWindow();

  if (!window) {
    return null;
  }

  const parsed = Bowser.getParser(window.navigator.userAgent);
  const { name } = parsed.getOS();

  return name;
};
