import React from 'react';

/* @Components */
import { Container } from 'components/Container';
import { LogoIcon } from './submodules/LogoIcon';

/* @Styles */
import './header.scss';

export const HeaderComponent = () => {
  return (
    <header>
      <Container className="container-header">
        <a href="https://macpaw.com/" target="_blank" rel="noopener noreferrer">
          <LogoIcon />
        </a>
      </Container>
    </header>
  );
};
