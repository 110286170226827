/* @Config */
import { DEBUG_ANALYTICS_LOG } from 'constants/config';

/* @Analytic clients */
import GtmClient from './clients/Gtm';

class Analytics {
  constructor(gtmClient) {
    this.gtmClient = gtmClient;
    this.isInitialize = false;
  }

  initialize() {
    if (!this.gtmClient) {
      this.debugLog('GTM client is not defined');

      return;
    }

    this.gtmClient.initialize();
    this.isInitialize = true;
  }

  trackPageView(options) {
    this.debugLog('trackPageView', options);

    if (!this.isInitialize) {
      this.debugLog('Analytics is not send to server. If you need it, enable it with ENV variable');

      return;
    }

    if (!this.gtmClient) {
      this.debugLog('GTM client is not defined');

      return;
    }

    if (!options) {
      this.debugLog('Params options is not exists in sendEvent function');

      return;
    }

    this.gtmClient.trackPageView(options);
  }

  /**
   * @param {object} options
   * @param {object.string} options.action
   * @param {object.string=} options.event
   * @param {object.string=} options.category
   * @param {object.string=} options.label
   * @param {object.string=} options.nonInteraction
   */
  sendEvent(options) {
    this.debugLog('sendEvent', options);

    if (!this.isInitialize) {
      this.debugLog('Analytics is not send to server. If you need it, enable it with ENV variable');

      return;
    }

    if (!this.gtmClient) {
      this.debugLog('GTM client is not defined');

      return;
    }

    if (!options) {
      this.debugLog('Params options is not exists in sendEvent function');

      return;
    }

    this.gtmClient.sendEvent(options);
  }

  debugLog(...options) {
    if (DEBUG_ANALYTICS_LOG) {
      // eslint-disable-next-line
      console.log('Analytics log:', ...options);
    }
  }
}

const gtmClient = new GtmClient();
const analytics = new Analytics(gtmClient);

export default analytics;
