import { useEffect } from 'react';
import { renderRoutes } from 'react-router-config';

/* @Router config */
import { routerConfig } from 'routes/config';

/* @Services */
import analytics from 'services/analytics';

/* @Config */
import { IS_DEVELOPMENT } from 'constants/config';

/* @Styles */
import './app.scss';

export const AppComponent = () => {
  useEffect(() => {
    if (!IS_DEVELOPMENT) {
      analytics.initialize();
    }
  }, []);

  return renderRoutes(routerConfig);
};
