import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

/* @Constants */
import { SENTRY_DSN, APP_ENV } from 'constants/config';

export const sentryLogger = {
  init() {
    this._initSentry();
  },

  track({ error, type, extraInfo }) {
    this._track({ error, type, extraInfo });
  },

  _track({ error, type, extraInfo }) {
    Sentry.withScope((scope) => {
      scope.setLevel(type);

      if (typeof extraInfo === 'object') {
        scope.setExtras(extraInfo);
      }

      Sentry.captureException(error);
    });
  },

  _initSentry() {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: APP_ENV,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
    });
  },
};
