import React from 'react';
import { func, shape, string } from 'prop-types';

/* @Hooks */
import { useIntl } from 'react-intl';

/* @Images */
import dots from 'images/dots.svg';

const propTypes = {
  onOpenBlog: func,
  secondaryCta: shape({
    title: string,
    url: string,
  }).isRequired,
};

const DEFAULT_CTA_URL = 'https://macpaw.com/blog';

export const BlogInfoComponent = ({ onOpenBlog, secondaryCta }) => {
  const { formatMessage: trans } = useIntl();

  const secondaryCtaTitle = secondaryCta.title || trans({ id: 'checkOurBlog' });
  const secondaryCtaUrl = secondaryCta.url || DEFAULT_CTA_URL;

  return (
    <div className="blog-info">
      <img src={dots} width="56" height="56" alt="" aria-hidden="true" />
      <p>{trans({ id: 'checkUsefulReads' })}</p>
      <a href={secondaryCtaUrl} onClick={onOpenBlog} className="blog" target="_blank" rel="noopener noreferrer">
        {secondaryCtaTitle}
      </a>
    </div>
  );
};

BlogInfoComponent.propTypes = propTypes;
