import React from 'react';
import { shape, string } from 'prop-types';

/* @Services */
import analytics from 'services/analytics';

/* @Components */
import { ErrorComponent } from './ErrorComponent';

/* @Constants */
import * as ANALYTICS from 'constants/analytics';

const propTypes = {
  productName: string,
  productToDisplay: string,
  fallbackCta: shape({
    title: string,
    url: string,
  }),
};

export const ErrorContainer = ({ productName = '', productToDisplay = '', fallbackCta }) => {
  /* @handlers */
  const handleDownloadApp = () => {
    analytics.sendEvent({ action: ANALYTICS.ACTION.CLICK_DOWNLOAD, label: productName });
  };

  return (
    <ErrorComponent productToDisplay={productToDisplay} fallbackCta={fallbackCta} onDownloadApp={handleDownloadApp} />
  );
};

ErrorContainer.propTypes = propTypes;
