import React from 'react';

/* @Pages */
import { MainPage } from 'pages/Main';
import { Page404 } from 'pages/Page404';

/* @Layouts */
import { Layout } from 'components/Layout';

/* @Component */
import { AppRoute } from 'components/AppRoute';

/* @Constants */
import { ROUTER_PATHS } from './paths';

export const routerConfig = [
  {
    path: ROUTER_PATHS.MAIN,
    exact: true,
    render: () => <AppRoute layout={Layout} component={MainPage} />,
  },
  {
    render: () => <AppRoute layout={Layout} component={Page404} />,
  },
];
