/* @Constants */
import { IS_DEVELOPMENT } from 'constants/config';

/* @Logger factory */
import { loggerFactory } from './loggerFactory';

/* @Logger clients */
import { consoleLogger } from './clients/consoleLogger';
import { sentryLogger } from './clients/sentryLogger';

const logger = (() => {
  let loggerClient = null;

  if (IS_DEVELOPMENT) {
    loggerClient = consoleLogger;
  } else {
    loggerClient = sentryLogger;
  }

  return loggerFactory(loggerClient);
})();

export { logger };
