/* @Config */
import { DEBUG_ANALYTICS_LOG } from 'constants/config';

/* @Constants */
import * as ANALYTICS from 'constants/analytics';

const GTM_CLIENT_KEY = 'GTM-KW4BC5';

const DEFAULT_OPTIONS = {
  event: ANALYTICS.EVENT.MACPAW,
  category: ANALYTICS.CATEGORY.APP,
  label: ANALYTICS.LABEL.CLEAN_MY_MAC_X,
};

class GtmClient {
  initialize() {
    /* eslint-disable */
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GTM_CLIENT_KEY);
    /* eslint-enable */
  }

  /**
   * @param {object} options
   */
  trackPageView(options) {
    const gtmOptions = this.makeOptions({
      ...options,
      action: ANALYTICS.ACTION.PAGE_SHOWN,
      nonInteraction: ANALYTICS.NON_INTERACTION.TRUE,
    });

    window.dataLayer.push(gtmOptions);
  }

  /**
   * @param {object} options
   * @param {object.string} options.action
   * @param {object.string=} options.event
   * @param {object.string=} options.category
   * @param {object.string=} options.label
   */
  sendEvent(options) {
    const gtmOptions = this.makeOptions(options);

    window.dataLayer.push(gtmOptions);
  }

  makeOptions(options = {}) {
    const fullOptions = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    // validation
    if (!fullOptions.action) {
      this.debugLog('Try to send event without action field');

      return;
    }

    if (!Object.values(ANALYTICS.ACTION).includes(fullOptions.action)) {
      this.debugLog(`Action ${fullOptions.action} in not exists in ANALYTICS.ACTION`);

      return;
    }

    //mapping
    const gtmOptions = this.mapAnalyticsToGtmOptions(fullOptions);

    return gtmOptions;
  }

  /**
   * @param {object} options
   * @param {object.string} options.event
   * @param {object.string} options.category
   * @param {object.string} options.action
   * @param {object.string} options.label
   * @param {object.string=} options.nonInteraction
   */
  mapAnalyticsToGtmOptions(options) {
    const gtmOptions = {};

    if (options.event) {
      gtmOptions.event = options.event;
    }

    if (options.category) {
      gtmOptions.eventCategory = options.category;
    }

    if (options.action) {
      gtmOptions.eventAction = options.action;
    }

    if (options.label) {
      gtmOptions.eventLabel = options.label;
    }

    if (options.nonInteraction) {
      gtmOptions.eventNonInteraction = options.nonInteraction;
    }

    return gtmOptions;
  }

  debugLog(...options) {
    if (DEBUG_ANALYTICS_LOG) {
      // eslint-disable-next-line
      console.log('GTM client log: ', ...options);
    }
  }
}

export default GtmClient;
