import React from 'react';
import { shape, string } from 'prop-types';

/* @Components */
import { BlogInfoComponent } from './BlogInfoComponent';

/* @Services */
import analytics from 'services/analytics';

/* @Constants */
import * as ANALYTICS from 'constants/analytics';

const propTypes = {
  productName: string,
  secondaryCta: shape({
    title: string,
    url: string,
  }),
};

export const BlogInfoContainer = ({ productName = '', secondaryCta = {} }) => {
  const handleOpenBlog = () => {
    analytics.sendEvent({ action: ANALYTICS.ACTION.CLICK_OPEN_BLOG, label: productName });
  };

  return <BlogInfoComponent secondaryCta={secondaryCta} onOpenBlog={handleOpenBlog} />;
};

BlogInfoContainer.propTypes = propTypes;
