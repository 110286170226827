import queryString from 'query-string';
import urlLib from 'url';

/* @Config */
import { API_URL } from 'constants/config';

export const createRequestOptions = (options) => {
  const { method, headers = {}, data } = options;
  const requestOptions = {};

  if (typeof method !== 'string') {
    throw new Error(`Request error: method param is not defined, current value -- ${method}`);
  }

  const requestHeaders = new Headers(headers);

  if (typeof data !== 'undefined') {
    requestHeaders.append('Content-Type', 'application/json');
    requestOptions.body = JSON.stringify(data);
  }

  requestOptions.headers = requestHeaders;
  requestOptions.credentials = 'include';
  requestOptions.method = method;

  return requestOptions;
};

export const createUrlWithQuery = (url, query) => {
  if (typeof url !== 'string') {
    throw new Error('createUrlWithQuery error: required argument url is not defined');
  }

  const apiCallUrl = urlLib.resolve(API_URL, url);

  if (typeof query === 'undefined') {
    return apiCallUrl;
  }

  let normalizeQuery = '';

  if (typeof query === 'string') {
    // add compose to this normalization
    normalizeQuery = queryString.stringify(queryString.parse(query));
  } else {
    normalizeQuery = queryString.stringify(query);
  }

  return `${apiCallUrl}?${normalizeQuery}`;
};
