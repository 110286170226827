import { request } from 'utils/request';

export const urlApi = {
  getUrl: async ({ shortId, locale }) => {
    const url = `/show/short-id/${shortId}`;
    const method = 'get';

    const urlInstance = await request({ url, method, query: { locale } });

    return urlInstance;
  },
};
