export class RequestError extends Error {
  constructor(...params) {
    const errorParams = [...params];
    const statusCode = params.pop();

    super(...errorParams);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }

    this.statusCode = statusCode;
  }
}
