import React, { useEffect, useContext } from 'react';
import { string, shape } from 'prop-types';
import { browserDeeplink } from '@macpaw/browser-deeplink';

/* @Services */
import analytics from 'services/analytics';

/* @Components */
import { SuccessComponent } from './SuccessComponent';

/* @Constants */
import * as ANALYTICS from 'constants/analytics';

/* @Context */
import { UserAppInfoContext, USER_APP_INFO_STATUS } from 'contexts/UserAppInfoContext';

const propTypes = {
  productName: string,
  productToDisplay: string,
  h1Title: string,
  headerText: string,
  deepLink: shape({
    url: string,
    title: string,
  }).isRequired,
};

export const SuccessContainer = ({
  productName = '',
  productToDisplay = '',
  h1Title = '',
  headerText = '',
  deepLink,
}) => {
  const { setUserAppInfoStatus } = useContext(UserAppInfoContext);

  useEffect(() => {
    analytics.sendEvent({
      action: ANALYTICS.ACTION.APP_INSTALLED_PAGE_SHOWN,
      nonInteraction: ANALYTICS.NON_INTERACTION.TRUE,
      label: productName,
    });
  }, [productName]);

  /* @handlers */
  const handleLaunchApp = async (e) => {
    e.preventDefault();

    try {
      await browserDeeplink(deepLink?.url ?? '');
      analytics.sendEvent({ label: productName, action: ANALYTICS.ACTION.CLICK_LAUNCH_APP });
      setUserAppInfoStatus(USER_APP_INFO_STATUS.INSTALLED);
    } catch (error) {
      analytics.sendEvent({ label: productName, action: ANALYTICS.ACTION.CLICK_LAUNCH_APP });
      analytics.sendEvent({
        label: productName,
        action: ANALYTICS.ACTION.APP_NOT_INSTALLED_PAGE_SHOWN,
        nonInteraction: ANALYTICS.NON_INTERACTION.TRUE,
      });
      setUserAppInfoStatus(USER_APP_INFO_STATUS.NOT_INSTALLED);
    }
  };

  return (
    <SuccessComponent
      productToDisplay={productToDisplay}
      h1Title={h1Title}
      headerText={headerText}
      deepLink={deepLink}
      onLaunchApp={handleLaunchApp}
    />
  );
};

SuccessContainer.propTypes = propTypes;
